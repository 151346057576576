import React from "react";
import { Link } from "gatsby";
import { TechPageContextType } from "templates/Blog";

import * as styles from "./Pagination.module.scss";

const Pagination = ({
  previousPagePath,
  pageNumber,
  nextPagePath,
  numberOfPages,
}: TechPageContextType) => {
  const pageNumberArray = [];

  // TODO : 이 로직을 봤을 때 10단위로 분할되는게 아닌, 그냥 일렬로 쭉 나열되는것 같습니다.
  // TODO : 개선이 필요한 부분으로 보입니다
  for (let i = 0; i < numberOfPages; i++) {
    pageNumberArray.push(i + 1);
  }

  return (
    <section className={styles.container}>
      <div className={styles.nextPrevBox}>
        <div className={styles.wrapper}>
          {previousPagePath ? (
            <Link className={styles.pageBtn} to={previousPagePath}>
              <span className={`${styles.arrow} ${styles.left}`} />
              <span>Prev</span>
            </Link>
          ) : (
            <span className={[styles.pageBtn, styles.disabled].join(" ")}>
              <span className={`${styles.arrow} ${styles.left}`} />
              <span>Prev</span>
            </span>
          )}
          {nextPagePath ? (
            <Link className={styles.pageBtn} to={nextPagePath}>
              <span>Next</span>
              <span className={styles.arrow} />
            </Link>
          ) : (
            <span className={[styles.pageBtn, styles.disabled].join(" ")}>
              <span>Next</span>
              <span className={styles.arrow} />
            </span>
          )}
        </div>
      </div>

      <div className={styles.paginationBox}>
        <div className={styles.wrapper}>
          {previousPagePath && (
            <Link
              className={`${styles.arrow} ${styles.left}`}
              to={previousPagePath}
            ></Link>
          )}
          <div className={styles.number}>
            {pageNumberArray.map((value) => (
              <Link
                key={value}
                to={`${value === 1 ? `/` : `/page/${value}`}`}
                className={styles.item}
                activeClassName={`${value === pageNumber ? styles.active : ""}`}
              >
                {value}
              </Link>
            ))}
          </div>
          {nextPagePath && (
            <Link className={styles.arrow} to={nextPagePath}></Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default Pagination;
